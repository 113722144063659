import { useRef, useState } from 'react'
import { Accordion, Button, Flex, Slider, Textarea, Text, rem, Select } from '@mantine/core'
import ImageObserved from '../pipeline/Image'
import { PipelineParameters } from '../pipeline/Pipeline'
import { NOTIFICATION_SERVICE } from '../../../components/notifications/NotificationService'
import { UploadCarPerspectiveButton } from '../pipeline/UploadCarPerspectiveButton'
import CanvasDraw from "react-canvas-draw";
// {
//   images: { url: string, preview: string }[];
//   previewHdrl: string;
//   enhancedHdrlFile: string;
//   modelVersion?: string;
//   loraName?: string;
// }[]
export default function PlaceCarImageV4(props: any) {

  const {
    mainColor,
    secretModeEnabled,
    // latestCustomModelsRun,
    runAsyncConvertBase64,
    runCreatePipeline,
    setBillingModalOpen,
    setPipelines,
    gallery,
    redux
    // carLoraOptions,
  } = props


  const renderRef = useRef<CanvasDraw>(null);

  const [controlnet, setControlnet] = useState(0.5)
  const [loraStreight, setLoraStreight] = useState(1.3)
  const [carLoraStreight, setCarLoraStreight] = useState(0.9)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loraName, setLoraName] = useState('')
  const [perspective, setPerspective] = useState('')
  const [background, setBackground] = useState('')
  const [carLoraName, setCarLoraName] = useState('')
  const [prompt, setPrompt] = useState('')

  console.log('gallery', gallery)

  console.log(`zuban/magai:${loraName}`)
  console.log(gallery.find(itm => itm.loraPath))
  const images = gallery.find(itm => itm.loraPath === loraName)?.images || []
  console.log(images)
  return (
    <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

      <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
        <Text fz={20}>Place Car V4</Text>
        <Textarea
          data-intercom-target={
            'promptInfo'
          }
          w="100%"
          minRows={7}
          size="sm"
          label="Location Prompt"
          value={prompt}
          description=""
          placeholder="Street view"
          onChange={(e) => {
            console.log(e)
            setPrompt(e.currentTarget.value)
          }}
        />
        <Select
          w="300px"
          size="sm"
          description=""
          label="Select car lora"
          placeholder="Pick value"
          value={carLoraName}
          onChange={(value) => {
            setCarLoraName(value!)
          }}
          mb={10}
          data={
            secretModeEnabled ? [
              // { value: 'mag_gen', label: 'Genesis' },
              { value: 'mag_eqs', label: 'Mercedes EQS (Demo car)' },
              { value: 'mag_gen_v2', label: 'Genesis v3' },
              { value: 'mag_gen', label: 'Genesis Latest' },
              // { value: 'mag_gen_v3', label: 'Genesis V3' },
              { value: 'u_tiguan', label: 'VW Tiguan (Demo car)' },
              { value: 'u_atlas', label: 'VW Atlas' },
            ] : [
            ]
          }
        />


        <UploadCarPerspectiveButton onSetImage={(image) => {
          setPerspective(image)
        }} />
        <UploadCarPerspectiveButton
          text="Background"
          onSetImage={(image) => {
            setBackground(image)
          }} />

        <Accordion chevronPosition="left" defaultValue="Apples">
          <Accordion.Item key={'key'} value={'value'}>
            <Accordion.Control>Advanced settings</Accordion.Control>
            <Accordion.Panel>
              <Flex direction={'column'} gap={12}>
                <Text
                  size="xs"
                  fz={14}
                  mt={12}
                >
                  Controlnet streight (0.5 is default)
                </Text>
                <Slider
                  size="xs"
                  color={mainColor}
                  sx={{ maxWidth: 360 }}
                  mt={0}
                  min={0}
                  max={2}
                  step={0.01}
                  label={controlnet}
                  value={+controlnet}
                  onChangeEnd={(e) => {
                    setControlnet((prevState) => (+e.toFixed(2)))
                  }}
                />

                <Text
                  size="xs"
                  fz={14}
                  mt={12}
                >
                  Location lora streight (1.3 is default)
                </Text>
                <Slider
                  size="xs"
                  color={mainColor}
                  sx={{ maxWidth: 360 }}
                  mt={0}
                  min={0}
                  max={2}
                  step={0.01}
                  label={loraStreight}
                  value={+loraStreight}
                  onChangeEnd={(e) => {
                    setLoraStreight((prevState) => (+e.toFixed(2)))
                  }}
                />

                <Text
                  size="xs"
                  fz={14}
                  mt={12}
                >
                  Car lora streight (0.9 is default)
                </Text>
                <Slider
                  size="xs"
                  color={mainColor}
                  sx={{ maxWidth: 360 }}
                  mt={0}
                  min={0}
                  max={2}
                  step={0.01}
                  label={carLoraStreight}
                  value={+carLoraStreight}
                  onChangeEnd={(e) => {
                    setCarLoraStreight((prevState) => (+e.toFixed(2)))
                  }}
                />
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Flex gap={12} mt={32} justify={'end'}>
          <Button
            styles={{
              root: {
                color: 'rgba(230, 230, 230, 1)',
                height: rem(36),

                fontSize: '14px',
                background: `${mainColor} !important`,
                border: 'none',
                position: 'relative',
              }
            }}
            // disabled={modelParams.prompt.length === 0}
            variant="default"
            onClick={async () => {

              if (renderRef) {
                const image = renderRef.current?.getDataURL('image/png', false, '#000')
                const { url } = await runAsyncConvertBase64({ url: image })
                console.log(url)

                // NEW
                const pipeline: PipelineParameters = {
                  provider: 'comfy',
                  type: 'placeCarImageV4',
                  loraName: loraName,
                  carLoraName: carLoraName,
                  streight: controlnet.toString(),
                  loraStreight: loraStreight.toString(),
                  carLoraStreight: carLoraStreight.toString(),
                  credits: 0,
                  inputSrc: perspective,
                  backgroundSrc: background,
                  maskSrc: url,
                  prompt: prompt,
                  negativePrompt: '',
                  redux: redux,
                  srcset: [],
                }
                const res = await runCreatePipeline(pipeline);

                if (res.error) {
                  NOTIFICATION_SERVICE.errorNotification(
                    "Not enough credits"
                  );
                  setBillingModalOpen(true)
                } else {
                  setPipelines((pps: any) => [{
                    ...res.data,
                    status: res.status
                  }, ...pps])

                  // setLatestCustomModelsRun([
                  //   {
                  //     srcset: [],
                  //     jobId: res.data.jobId
                  //   }
                  // ])
                }
              }




            }}
          >
            Generate
          </Button>
        </Flex>
      </Flex>
      {
        background ? <Flex h={'1024px'} w={'1024px'} style={{
          position: 'relative',
        }}>
          <div
            style={{
              position: 'relative',
              zIndex: 1,
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.2)',
            }}

          >
            <CanvasDraw
              ref={renderRef}
              backgroundColor='#00000028'
              lazyRadius={0}
              hideGrid={true}
              brushRadius={30}
              brushColor={'#ffff'}
              canvasHeight={1024}
              canvasWidth={1024}
            />
          </div>
          <ImageObserved
            style={{
              background: '#2424263f',
              position: 'absolute',
              borderRadius: '4px',
              left: 0,
              padding: 0,
            }}
            width={'1024px'}
            height={'1024px'}
            dark
            objectFit='cover'
            src={background}
          />

        </Flex> : null
      }

    </Flex>
  )
}
