import { useState } from 'react'
import { Button, Flex, Text, rem } from '@mantine/core'
import ImageObserved from '../pipeline/Image'
import { MODE, priceMap } from '../pipeline/Main'
import { PipelineParameters } from '../pipeline/Pipeline'
import { NOTIFICATION_SERVICE } from '../../../components/notifications/NotificationService'

export default function Outpaint(props: any) {

  const {
    image,
    runCreatePipeline,
    setBillingModalOpen,
    setPipelines,
    pipelines,
    setMode
  } = props



  const generateButtton = (model: { credits: number, text?: string, intercom?: string }) => {
    return <Flex
      gap={12}
      align={'center'}
      data-intercom-target={
        model.intercom
      }>
      <Text>
        {typeof model.text === 'string' ? model.text : 'Generate'}
      </Text>
      <Flex align={'center'} style={{
        borderRadius: '12px', background: '#f85e39',
        padding: '0 8px'
      }}>

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.98014 1.33337C4.30014 1.33337 1.31348 4.32004 1.31348 8.00004C1.31348 11.68 4.30014 14.6667 7.98014 14.6667C11.6601 14.6667 14.6468 11.68 14.6468 8.00004C14.6468 4.32004 11.6668 1.33337 7.98014 1.33337ZM10.4801 8.23337L8.00014 11.0534L7.70681 11.3867C7.30014 11.8467 6.96681 11.7267 6.96681 11.1067V8.46671H5.83348C5.32014 8.46671 5.18014 8.15337 5.52014 7.76671L8.00014 4.94671L8.29348 4.61337C8.70014 4.15337 9.03348 4.27337 9.03348 4.89337V7.53337H10.1668C10.6801 7.53337 10.8201 7.84671 10.4801 8.23337Z" fill="white" />
        </svg>
        <Text >{model.credits}</Text>
      </Flex >
    </Flex>
  }
  const [enlargeParams, setEnlargeParams] = useState({
    url: '',
    left: 250,
    right: 250,
    bottom: 250,
    top: 250,
  });
  return (
    <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

      <Flex direction={'column'} w={'30%'} gap={12}>
        <Text fz={20}>Choose the enlarge option</Text>

        {/* {
          [{
            top: 250,
            left: 0,
            right: 0,
            bottom: 0,

          }, {
            top: 0,
            left: 250,
            right: 0,
            bottom: 0,

          },
          {
            top: 0,
            left: 0,
            right: 250,
            bottom: 0,

          },
          {
            top: 0,
            left: 0,
            right: 0,
            bottom: 250,

          },
          ].map(itm => (
            <Button
              size="lg"
              style={{
                border: '1px solid black',
                fontSize: '14px',
              }}
              color="rgba(255, 255, 255, 1)"
              variant={
                enlargeParams.left === itm.left && 
                enlargeParams.top === itm.top &&
                enlargeParams.right === itm.right &&
                enlargeParams.bottom === itm.bottom
                ? "default" : 'outline'}
              onClick={() => {
                setEnlargeParams({
                  ...enlargeParams,
                  left: itm.left,
                  right: itm.right,
                  top: itm.top,
                  bottom: itm.bottom,
                })
              }}
            >{itm.left === 250 ? 'Left' : itm.top === 250 ? 'Top' : itm.right === 250 ? 'Right' : 'Bottom'}</Button>
          ))
        } */}
        <Button
          size="lg"
          style={{
            border: '1px solid black',
            fontSize: '14px',
          }}
          color="rgba(255, 255, 255, 1)"
          variant={enlargeParams.left === 250 && enlargeParams.top === 250 && enlargeParams.right === 250 && enlargeParams.right === 250 ? "default" : 'outline'}
          onClick={() => {
            setEnlargeParams({
              ...enlargeParams,
              left: 250,
              right: 250,
              top: 250,
              bottom: 250,
            })
          }}
        >From all side</Button>
      
        <Flex gap={12} mt={32} justify={'end'}>
          <Button
            styles={{
              root: {
                color: 'rgba(230, 230, 230, 1)',
                height: rem(36),

                fontSize: '14px',
                background: '#f53c02 !important',
                border: 'none',
                position: 'relative',
              }
            }}
            variant="default"
            onClick={async () => {
              const pipeline: PipelineParameters = {
                provider: 'replicate',
                type: 'enlarge',
                outpaintUp: enlargeParams.top,
                outpaintDown: enlargeParams.bottom,
                outpaintLeft: enlargeParams.left,
                outpaintRight: enlargeParams.right,
                inputSrc: image,
                outputSrc: '',
                size: 1,
                credits: priceMap.OUTPAINT,
              }
              const res = await runCreatePipeline(pipeline);

              if (res.error) {
                NOTIFICATION_SERVICE.errorNotification(
                  "Not enough credits"
                );
                setBillingModalOpen(true)
              } else {
                setPipelines([{
                  ...res.data,
                  status: res.status
                }, ...pipelines])

                setEnlargeParams({
                  top: 250,
                  left: 250,
                  right: 250,
                  bottom: 250,
                  url: '',
                })
                setMode(MODE.PIPELINES)
              }
            }}
          >
            {generateButtton({ credits: priceMap.OUTPAINT, intercom: 'enlargeGenerateButton' })}
          </Button>
        </Flex>
      </Flex>
      <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
        <ImageObserved
          width={'600px'}
          height={'600px'}

          dark
          src={image}
        />
      </Flex>
    </Flex>
  )
}
