import { action, makeObservable, observable } from "mobx";

export class SpaceStore {
  @observable ratio: string = '';
  @observable prompt: string = '';
 
  constructor() {
    makeObservable(this);
  }

  @action
  setRatio = (ratio: string) => {
    this.ratio = ratio
  }
  @action
  setPrompt = (prompt: string) => {
    this.prompt = prompt
  }

}
