import { forwardRef, useState } from 'react'
import { Accordion, Button, Flex, Slider, Textarea, Text, rem, Select } from '@mantine/core'
import ImageObserved from '../pipeline/Image'
import { PipelineParameters } from '../pipeline/Pipeline'
import { NOTIFICATION_SERVICE } from '../../../components/notifications/NotificationService'
import { PromptGeneratorButton } from '../pipeline/PromptGeneratorButton'
import { UploadCarPerspectiveButton } from '../pipeline/UploadCarPerspectiveButton'

// {
//   images: { url: string, preview: string }[];
//   previewHdrl: string;
//   enhancedHdrlFile: string;
//   modelVersion?: string;
//   loraName?: string;
// }[]
export default function PlaceCarImageV3(props: any) {

  const {
    mainColor,
    secretModeEnabled,
    // latestCustomModelsRun,
    modelParams,
    setModelParams,
    // runAsyncConvertBase64,
    runCreatePipeline,
    setBillingModalOpen,
    setLatestCustomModelsRun,
    setPipelines,
    gallery,
    redux
    // carLoraOptions,
  } = props


  const [controlnet, setControlnet] = useState(0.5)
  const [loraStreight, setLoraStreight] = useState(1.3)
  const [carLoraStreight, setCarLoraStreight] = useState(0.9)
  const [loraName, setLoraName] = useState('')
  const [perspective, setPerspective] = useState('')
  const [background, setBackground] = useState('')
  const [carLoraName, setCarLoraName] = useState('')

  console.log('gallery', gallery)

  const SelectItem = forwardRef<HTMLDivElement, any>(
    (option: any, ref) => (
      <div ref={ref} {...option}>
        <Flex ref={ref} align="center">
          <Flex h={80} w={80}>
            <ImageObserved
              width={80}
              height={80}
              dark
              src={option.preview}
            />
          </Flex>
          <Text ml={10}>{option.label}</Text>
        </Flex>
      </div>
    )
  );

  console.log(`zuban/magai:${loraName}`)
  console.log(gallery.find(itm => itm.loraPath))
  const images = gallery.find(itm => itm.loraPath === loraName)?.images || []
  console.log(images)
  return (
    <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

      <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
        <Text fz={20}>Place Car V3</Text>


        <Flex justify={'space-between'}>
          {(images)
            .slice(0, 6)
            .map((img, index) => {
              return <Flex
                key={index}
                h="auto"
                p={2}
                w={'33.3%'}
                onClick={() => {
                  // setBacgroundSrc({
                  //     url: img
                  // })
                }}
              >
                <ImageObserved
                  width={'100%'}
                  height={'auto'}
                  src={img}
                />
              </Flex>
            })
          }
        </Flex>
        <Select
          w="300px"
          size="sm"
          description=""
          label="Select location lora"
          placeholder="Pick value"
          value={loraName}
          onChange={(value) => {
            console.log(value)
            setLoraName(value!)
          }}


          itemComponent={SelectItem}
          mb={10}
          data={
            gallery.filter(itm => itm.loraPath).map(itm => (
              { value: itm.loraPath, label: itm.loraPath.replace('.safetensors', ''), preview: itm.images[0].preview }
            ))
          }
        />
        {
          loraName ? <>
            <Textarea
              data-intercom-target={
                'promptInfo'
              }
              w="100%"
              minRows={7}
              size="sm"
              label="Location Prompt"
              value={modelParams.prompt}
              description=""
              placeholder="Street view"
              onChange={(e) => {
                setModelParams({
                  ...modelParams,
                  prompt: e.currentTarget.value
                })
              }}
            />

            <PromptGeneratorButton
              image={images[0]}
              images={images}
              onSetPrompt={(prompt) => {
                setModelParams({
                  ...modelParams,
                  prompt: prompt
                })
              }}
            /></> : null
        }

        <Select
          w="300px"
          size="sm"
          description=""
          label="Select car lora"
          placeholder="Pick value"
          value={carLoraName}
          onChange={(value) => {
            setCarLoraName(value!)
          }}
          mb={10}
          data={
            secretModeEnabled ? [
              // { value: 'mag_gen', label: 'Genesis' },
              { value: 'mag_eqs', label: 'Mercedes EQS (Demo car)' },
              { value: 'mag_gen_v2', label: 'Genesis v3' },
              { value: 'mag_gen', label: 'Genesis Latest' },
              // { value: 'mag_gen_v3', label: 'Genesis V3' },
              { value: 'u_tiguan', label: 'VW Tiguan (Demo car)' },
              { value: 'u_atlas', label: 'VW Atlas' },
            ] : [
              { value: 'mag_gen_v2', label: 'Genesis v3' },
              { value: 'mag_gen', label: 'Genesis Latest' },
            ]
          }
        />


        <UploadCarPerspectiveButton onSetImage={(image) => {
          setPerspective(image)
        }} />
        <UploadCarPerspectiveButton
          text="Background"
          onSetImage={(image) => {
            setBackground(image)
          }} />
        <Accordion chevronPosition="left" defaultValue="Apples">
          <Accordion.Item key={'key'} value={'value'}>
            <Accordion.Control>Advanced settings</Accordion.Control>
            <Accordion.Panel>
              <Flex direction={'column'} gap={12}>
                <Text
                  size="xs"
                  fz={14}
                  mt={12}
                >
                  Controlnet streight (0.5 is default)
                </Text>
                <Slider
                  size="xs"
                  color={mainColor}
                  sx={{ maxWidth: 360 }}
                  mt={0}
                  min={0}
                  max={2}
                  step={0.01}
                  label={controlnet}
                  value={+controlnet}
                  onChangeEnd={(e) => {
                    setControlnet((prevState) => (+e.toFixed(2)))
                  }}
                />

                <Text
                  size="xs"
                  fz={14}
                  mt={12}
                >
                  Location lora streight (1.3 is default)
                </Text>
                <Slider
                  size="xs"
                  color={mainColor}
                  sx={{ maxWidth: 360 }}
                  mt={0}
                  min={0}
                  max={2}
                  step={0.01}
                  label={loraStreight}
                  value={+loraStreight}
                  onChangeEnd={(e) => {
                    setLoraStreight((prevState) => (+e.toFixed(2)))
                  }}
                />

                <Text
                  size="xs"
                  fz={14}
                  mt={12}
                >
                  Car lora streight (0.9 is default)
                </Text>
                <Slider
                  size="xs"
                  color={mainColor}
                  sx={{ maxWidth: 360 }}
                  mt={0}
                  min={0}
                  max={2}
                  step={0.01}
                  label={carLoraStreight}
                  value={+carLoraStreight}
                  onChangeEnd={(e) => {
                    setCarLoraStreight((prevState) => (+e.toFixed(2)))
                  }}
                />
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Flex gap={12} mt={32} justify={'end'}>
          <Button
            styles={{
              root: {
                color: 'rgba(230, 230, 230, 1)',
                height: rem(36),

                fontSize: '14px',
                background: `${mainColor} !important`,
                border: 'none',
                position: 'relative',
              }
            }}
            disabled={modelParams.prompt.length === 0}
            variant="default"
            onClick={async () => {

              // const canvasParent = document.getElementById('controlnetpano') as HTMLCanvasElement
              // const canvas = canvasParent.querySelector('canvas') as HTMLCanvasElement
              // const image = canvas.toDataURL('png')
              // const { url } = await runAsyncConvertBase64({ url: image })


              const pipeline: PipelineParameters = {
                provider: 'comfy',
                type: 'placeCarImageV3',
                loraName: loraName,
                carLoraName: carLoraName,
                streight: controlnet.toString(),
                loraStreight: loraStreight.toString(),
                carLoraStreight: carLoraStreight.toString(),
                credits: 0,
                inputSrc: perspective,
                backgroundSrc: background,
                prompt: modelParams.prompt,
                negativePrompt: '',
                redux: redux,
                srcset: [],
              }
              const res = await runCreatePipeline(pipeline);

              if (res.error) {
                NOTIFICATION_SERVICE.errorNotification(
                  "Not enough credits"
                );
                setBillingModalOpen(true)
              } else {
                setPipelines((pps: any) => [{
                  ...res.data,
                  status: res.status
                }, ...pps])

                setLatestCustomModelsRun([
                  {
                    srcset: [],
                    jobId: res.data.jobId
                  }
                ])
              }


            }}
          >
            Generate
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
